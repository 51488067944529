<template>
  <div class="home">
    <base-list-wrapper />
  </div>
</template>

<script>
// import { store } from "@/store";
import BaseListWrapper from "@/components/BaseListWrapper.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { BaseListWrapper },
};
</script>
